// import $ from 'jquery';
import svg4everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import flexibility from 'flexibility';
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import isMobile from './modules/common/is-mobile.js';
import './modules/common/smoothScroll.js';
import './modules/common/lazyload.js';
import './modules/common/userAgent.js';
import './modules/common/wp_contact-form-7.js';
import './modules/common/sp-menu.js';//スマホメニュー
import './modules/common/megamenu.js';//メガメニュー
// import './modules/common/datepicker.js';
import './modules/common/tablefix.js';
// import './modules/common/highlight.js';
// import './modules/common/tab.js';
// import './modules/common/top.js';

flexibility(document.documentElement);//flexboxのie対応
objectFitImages();//object-fitのie対応
svg4everybody();//svgをieで表示

//電話番号スマホのみ掛ける
$("a[href*='tel']").on("click", function(e){
  if(!isMobile(768)){
    return false
  }
});

$(function() {
  var _window = $(window),
  _header = $('#l-header'),
  heroBottom;

  _window.on('load scroll',function(){
    heroBottom = 200;
    if(_window.scrollTop() > heroBottom){
      _header.addClass('is-animation');
    }
    else{
      _header.removeClass('is-animation');
    }
  });

  //スマホで画面の高さ取得・設定
  $(window).on('resize', function(){
    if(isMobile()) {
      $('.top-hero').css({
        'height': $(window).height()
      });
    } else {
      $('.top-hero').removeAttr('style');
    }
  }).trigger('resize');

  //グローバルナビのカレント設定
  // ページurlを"/"で区切った時の文字列を取得
  var currentCheckUrl = location.pathname.split("/")[1];
  var targetNav = $(".megamenu-wrapper");
  targetNav.each(function(){
    var href = $(this).find("a:first").attr("href").split("/")[1];

    if( href == currentCheckUrl ) {
 	 $(this).addClass("is-current");
    };
  });

  //別ページからのアンカーリンク対応
  var topMargin = winWidth >= 768 ? 80 : 50;
  var target = location.hash;
  if(target.length) {
     $('a[href^=#]').click(function(){
      var position = $(target).offset().top - topMargin;
      $("html, body").animate({scrollTop:position}, 100, "swing");
    });
  }

  //別ページからのアンカーリンク対応
  var winWidth = $(window).width();
  var topMargin = winWidth >= 768 ? 80 : 50;
  var target = location.hash;
  if(target.length) {
    $(window).on('load', function(){
      var position = $(target).offset().top - topMargin;
      $("html, body").animate({scrollTop:position}, 100, "swing");
    });
  }
});


function imageGuard(selector){
  var guard_selector = document.querySelectorAll(selector);
  for(var n = 0; n < guard_selector.length; n++){
    guard_selector[n].addEventListener("contextmenu", function(e){
      e.preventDefault();
    }, false);
  }

  var guard_style = {
    'pointer-events':'none',
    '-webkit-touch-callout':'none',
    '-moz-touch-callout':'none',
    'touch-callout':'none',
    '-webkit-user-select':'none',
    '-moz-user-select':'none',
    'user-select':'none'
  }

  Object.keys(guard_style).forEach(function(v, i, a){
    for(var n = 0; n < guard_selector.length; n++){
      guard_selector[n].style[v] = guard_style[v];
    }
  });

}

//pagetopの表示
$(function() {
    var topBtn = $('.c-scrollPageTop');
    topBtn.hide();
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
});

// document.addEventListener("DOMContentLoaded", function() {
//   imageGuard('img');
// });
//
// $('.l-header__logo').append('<img src="/assets/img/common/logo.png">');

// const hoge = val => val + 1;
// console.log(hoge(1));

// $(function(){
//   $(window).scroll(function (){
//     $('.effect-fade').each(function(){
//       var elemPos = $(this).offset().top;
//       var scroll = $(window).scrollTop();
//       var windowHeight = $(window).height();
//       if (scroll > elemPos - windowHeight + 100){
//         $(this).addClass('effect-scroll');
//       }
//     });
//   });
// });

window.onload = function() {
  scroll_effect();

  $(window).scroll(function(){
   scroll_effect();
  });

  function scroll_effect(){
   $('.effect-fade').each(function(){
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    if (scroll > elemPos - windowHeight + 100){
     $(this).addClass('effect-scroll');
    }
   });
  }
};
